export default {
  data () {
    return {
      dataMixinReportFields: [
        {
          label: '',
          key: 'isBlocked',
          tdClass: 'call-tabel-icon',
          thClass: 'call-tabel-icon call-tabel--span'
        },
        {
          label: 'ID',
          key: 'id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th call-tabel--span'
        },
        {
          label: 'Project/Operator',
          key: 'name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th call-th-full call-tabel--span'
        },

        {
          label: 'Calls Finished',
          key: 'outcoming_finished_calls_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Calls Cancelled',
          key: 'outcoming_cancelled_calls_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed Auto Assigned',
          key: 'auto_closed_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed VIP',
          key: 'auto_closed_vip_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed Manually',
          key: 'manually_closed_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Spoken',
          key: 'calls_duration',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'KPI',
          key: 'kpi',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action call-tabel--span'
        }
      ],

      dataMixinReportExpandedFields: [
        {
          label: '',
          key: 'isBlocked',
          tdClass: 'call-tabel-icon',
          thClass: 'call-tabel-icon call-tabel--span'
        },
        {
          label: 'ID',
          key: 'operator_id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th call-tabel--span'
        },
        {
          label: 'Project/Operator',
          key: 'operator_name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th call-th-full call-tabel--span'
        },

        {
          label: 'Calls Finished',
          key: 'outcoming_finished_calls_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Calls Cancelled',
          key: 'outcoming_cancelled_calls_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed Auto Assigned',
          key: 'auto_closed_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed VIP',
          key: 'auto_closed_vip_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed Manually',
          key: 'manually_closed_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Spoken',
          key: 'calls_duration',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'KPI',
          key: 'kpi',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action call-tabel--span'
        }
      ],

      dataMixinReportOperatorFields: [
        {
          label: '',
          key: 'isBlocked',
          tdClass: 'call-tabel-icon',
          thClass: 'call-tabel-icon call-tabel--span'
        },
        {
          label: 'ID',
          key: 'id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th call-tabel--span'
        },
        {
          label: 'Operator',
          key: 'name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th call-th-full call-tabel--span'
        },

        {
          label: 'Calls Finished',
          key: 'outcoming_finished_calls_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Calls Cancelled',
          key: 'outcoming_cancelled_calls_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed Auto Assigned',
          key: 'auto_closed_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed VIP',
          key: 'auto_closed_vip_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Closed Manually',
          key: 'manually_closed_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },

        {
          label: 'Spoken',
          key: 'calls_duration',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: 'KPI',
          key: 'kpi',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action call-tabel--span'
        }
      ],

      dataMixinReportOperatorExpandFields: [
        {
          label: 'ID',
          key: 'project_id',
          sortable: false,
          tdClass: 'call-td call-td--grey',
          thClass: 'call-th call-tabel--span'
        },
        {
          label: 'Project',
          key: 'project_name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th call-th-full call-tabel--span'
        },
        {
          label: 'KPI',
          key: 'kpi',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span'
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action call-tabel--span'
        }
      ]
    };
  },
  methods: {
    onHover (item) {
      this.$set(item, 'actions', true);
    },
    onUnHover (item) {
      this.$set(item, 'actions', false);
    }
  }
};
