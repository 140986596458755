import {
  faArrowDown,
  faArrowUp,
  faCheck,
  faCheckCircle,
  faUser,
  faClock,
  faComment,
  faGift,
  faPhone, faSms, faGaugeHigh
} from '@fortawesome/free-solid-svg-icons';
import CallButton from '../CallButton';
import CallBadgeTable from '../CallBadgeTable';

export default {
  name: 'CallCardReportDetail',
  components: {
    CallButton,
    CallBadgeTable
  },
  props: {
    objCard: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dataIconPhone: faPhone,
      dataIconClock: faClock,
      dataIconSms: faSms,
      dataIconComment: faComment,
      dataIconGift: faGift,
      dataIconCheck: faCheck,
      dataIconCheckCircle: faCheckCircle,
      dataIconUser: faUser,
      dataIconArrowUp: faArrowUp,
      dataIconArrowDown: faArrowDown,
      dataIconGaugeMax: faGaugeHigh
    };
  },
  render (h) {
    const _templatePositiveNumber = (value) => {
      if (+value < 0) {
        return value * -1;
      }
      return value;
    };

    return (
      <div class="activity__wrapper activity__wrapper--detail-report">
        <div class="activity__block">

          <div class="activity-item">
            <div class="activity-item__head">
              <span class="green">
                <font-awesome-icon
                  icon={this.dataIconPhone} />
              </span>
              &nbsp;Finished
            </div>
            <div class="activity-item__body">

              {this.objCard?.outcoming_finished_calls_count || 0}
            </div>
            <div class="activity-item__footer">
              <span class={this.objCard?.outcoming_finished_calls_count_difference >= 0 ? 'success' : 'danger'}>{this.objCard?.outcoming_finished_calls_count_difference > 0 && '+'}{this.objCard?.outcoming_finished_calls_count_difference || 0}</span>
              <p>|</p>
              <span class={this.objCard?.outcoming_finished_calls_count_difference_percents >= 0 ? 'success' : 'danger'}><font-awesome-icon icon={(this.objCard?.outcoming_finished_calls_count_difference >= 0 ? this.dataIconArrowUp : this.dataIconArrowDown)}/>&nbsp;{_templatePositiveNumber(this.objCard?.outcoming_finished_calls_count_difference_percents) || 0}%</span>
            </div>
          </div>

          <div class="activity-item">
            <div class="activity-item__head">
              <span class="red">
                <font-awesome-icon
                  icon={this.dataIconPhone} />
              </span>
              &nbsp;Cancelled
            </div>
            <div class="activity-item__body">

              {this.objCard?.outcoming_cancelled_calls_count || 0}
            </div>
            <div class="activity-item__footer">

              <span class={this.objCard?.outcoming_cancelled_calls_count_difference >= 0 ? 'success' : 'danger'}>{this.objCard?.outcoming_cancelled_calls_count_difference > 0 && '+'}{this.objCard?.outcoming_cancelled_calls_count_difference || 0}</span>
              <p>|</p>

              <span class={this.objCard?.outcoming_cancelled_calls_count_difference_percents >= 0 ? 'success' : 'danger'}><font-awesome-icon icon={(this.objCard?.outcoming_cancelled_calls_count_difference_percents >= 0 ? this.dataIconArrowUp : this.dataIconArrowDown)}/>&nbsp;{_templatePositiveNumber(this.objCard?.outcoming_cancelled_calls_count_difference_percents) || 0}%</span>
            </div>
          </div>

          <div class="activity-item">
            <div class="activity-item__head">
                <img src="/img/incoming-outline.svg"/>
              &nbsp;Incoming
            </div>
            <div class="activity-item__body">
              {this.objCard?.incoming_calls_count || 0}
            </div>
            <div class="activity-item__footer">

              <span
                class={this.objCard?.incoming_calls_count_difference >= 0 ? 'success' : 'danger'}>{this.objCard?.incoming_calls_count_difference > 0 && '+'}{this.objCard?.incoming_calls_count_difference || 0}</span>
              <p>|</p>

              <span class={this.objCard?.incoming_calls_count_difference_percents >= 0 ? 'success' : 'danger'}><font-awesome-icon
                icon={(this.objCard?.incoming_calls_count_difference_percents >= 0 ? this.dataIconArrowUp : this.dataIconArrowDown)}/>
                &nbsp;{_templatePositiveNumber(this.objCard?.incoming_calls_count_difference_percents) || 0}%</span>
            </div>
          </div>

          <div class="activity-item">
            <div class="activity-item__head">
              <span>
                <font-awesome-icon
                  icon={this.dataIconPhone}/>
              </span>
              &nbsp;Spoken
            </div>
            <div class="activity-item__body">

              {this.$options.filters.dateReport(this.objCard?.calls_duration) || 0}
            </div>
            <div class="activity-item__footer">

              <span class={this.objCard?.calls_duration_difference_percents >= 0 ? 'success' : 'danger'}>{this.$options.filters.dateReport(this.objCard?.calls_duration_difference) || 0}</span>
              <p>|</p>

              <span class={this.objCard?.calls_duration_difference_percents >= 0 ? 'success' : 'danger'}><font-awesome-icon icon={(this.objCard?.calls_duration_difference_percents >= 0 ? this.dataIconArrowUp : this.dataIconArrowDown)}/>&nbsp;{_templatePositiveNumber(this.objCard?.calls_duration_difference_percents) || 0}%</span>
            </div>
          </div>

          <div class="activity-item">
            <div class="activity-item__head">
              <span class="success">
                <font-awesome-icon
                  icon={this.dataIconSms}/>
              </span>
              &nbsp;SMS
            </div>
            <div class="activity-item__body">

              {this.objCard?.sms_count || 0}
            </div>
            <div class="activity-item__footer">
              <span
                class={this.objCard?.sms_count_difference_percents >= 0 ? 'success' : 'danger'}>{this.objCard?.sms_count_difference > 0 && '+'}{this.objCard?.sms_count_difference || 0}</span>
              <p>|</p>
              <span class={this.objCard?.sms_count_difference_percents >= 0 ? 'success' : 'danger'}><font-awesome-icon
                icon={(this.objCard?.sms_count_difference_percents >= 0 ? this.dataIconArrowUp : this.dataIconArrowDown)}/>
                &nbsp;{_templatePositiveNumber(this.objCard?.sms_count_difference_percents) || 0}%</span>
            </div>
          </div>

          <div class="activity-item">
            <div class="activity-item__head">
              <span class="success">
                <font-awesome-icon
                  icon={this.dataIconGift}/>
              </span>
              &nbsp;Gifts
            </div>
            <div class="activity-item__body">

              {this.objCard?.bonuses_count || 0}
            </div>
            <div class="activity-item__footer">
              <span
                class={this.objCard?.bonuses_count_difference_percents >= 0 ? 'success' : 'danger'}>{this.objCard?.bonuses_count_difference > 0 && '+'}{this.objCard?.bonuses_count_difference || 0}</span>
              <p>|</p>
              <span class={this.objCard?.bonuses_count_difference_percents >= 0 ? 'success' : 'danger'}><font-awesome-icon
                icon={(this.objCard?.bonuses_count_difference_percents >= 0 ? this.dataIconArrowUp : this.dataIconArrowDown)}/>
                &nbsp;{_templatePositiveNumber(this.objCard?.bonuses_count_difference_percents) || 0}%</span>
            </div>
          </div>

        </div>

        <div class="activity__block">
          <div class="activity-item">
            <div class="activity-item__head">
              <span>
                <font-awesome-icon
                  icon={this.dataIconCheckCircle}/>
              </span>
              &nbsp;Closed Free
            </div>
            <div class="activity-item__body">

              {this.objCard?.auto_closed_free_tasks_count || 0}
            </div>
            <div class="activity-item__footer">

              <span class={this.objCard?.auto_closed_free_tasks_count_difference >= 0 ? 'success' : 'danger'}>
                {this.objCard?.auto_closed_free_tasks_count_difference > 0 && '+'}{this.objCard?.auto_closed_free_tasks_count_difference || 0}</span>
              <p>|</p>

              <span class={this.objCard?.auto_closed_free_tasks_count_difference_percents >= 0 ? 'success' : 'danger'}>
                <font-awesome-icon icon={(this.objCard?.auto_closed_free_tasks_count_difference_percents >= 0 ? this.dataIconArrowUp : this.dataIconArrowDown)}/>
                &nbsp;{_templatePositiveNumber(this.objCard?.auto_closed_free_tasks_count_difference_percents) || 0}%</span>
            </div>
          </div>
        </div>

        <div class="activity__block">
          <div class="activity-item">
            <div class="activity-item__head">
              <span class="green">
                <font-awesome-icon
                  icon={this.dataIconCheckCircle}/>
              </span>
              &nbsp;Closed Assigned
            </div>
            <div class="activity-item__body">

              {this.objCard?.auto_closed_assigned_tasks_count || 0}
            </div>
            <div class="activity-item__footer">

              <span
                class={this.objCard?.auto_closed_assigned_tasks_count_difference >= 0 ? 'success' : 'danger'}>{this.objCard?.auto_closed_assigned_tasks_count_difference > 0 && '+'}{this.objCard?.auto_closed_assigned_tasks_count_difference || 0}</span>
              <p>|</p>

              <span class={this.objCard?.auto_closed_assigned_tasks_count_difference_percents >= 0 ? 'success' : 'danger'}><font-awesome-icon
                icon={(this.objCard?.auto_closed_assigned_tasks_count_difference_percents >= 0 ? this.dataIconArrowUp : this.dataIconArrowDown)}/>
                &nbsp;{_templatePositiveNumber(this.objCard?.auto_closed_assigned_tasks_count_difference_percents) || 0}%</span>
            </div>
          </div>
        </div>

        <div class="activity__block">
          <div class="activity-item">
            <div class="activity-item__head">
              <span class="green">
                <font-awesome-icon
                  icon={this.dataIconGaugeMax}/>
              </span>
              &nbsp;KPI
            </div>
            <div class="activity-item__body">
              {this.objCard?.kpi || 0}
            </div>
            <div class="activity-item__footer">

              <span
                class={this.objCard?.kpi_difference >= 0 ? 'success' : 'danger'}>{this.objCard?.kpi_difference > 0 && '+'}{this.objCard?.kpi_difference || 0}</span>
              <p>|</p>

              <span
                class={this.objCard?.kpi_difference_percents >= 0 ? 'success' : 'danger'}><font-awesome-icon
                icon={(this.objCard?.kpi_difference_percents >= 0 ? this.dataIconArrowUp : this.dataIconArrowDown)}/>
                &nbsp;{_templatePositiveNumber(this.objCard?.kpi_difference_percents) || 0}%</span>
            </div>
          </div>
        </div>

        <div class="activity__block">
          <div class="activity-item">
            <div class="activity-item__head">
              <span class="green">
                <font-awesome-icon
                  icon={this.dataIconUser}/>
              </span>
              &nbsp;VIP
            </div>
            <div class="activity-item__body">
              {this.objCard?.auto_closed_assigned_vip_tasks_count || 0}
            </div>
            <div class="activity-item__footer">

              <span
                class={this.objCard?.auto_closed_assigned_vip_tasks_count_difference >= 0 ? 'success' : 'danger'}>{this.objCard?.auto_closed_assigned_vip_tasks_count_difference > 0 && '+'}{this.objCard?.auto_closed_assigned_vip_tasks_count_difference || 0}</span>
              <p>|</p>

              <span
                class={this.objCard?.auto_closed_assigned_vip_tasks_count_difference_percents >= 0 ? 'success' : 'danger'}><font-awesome-icon
                icon={(this.objCard?.auto_closed_assigned_vip_tasks_count_difference_percents >= 0 ? this.dataIconArrowUp : this.dataIconArrowDown)}/>
                &nbsp;{_templatePositiveNumber(this.objCard?.auto_closed_assigned_vip_tasks_count_difference_percents) || 0}%</span>
            </div>
          </div>
        </div>

        <div class="activity__block">
          <div class="activity-item">
            <div class="activity-item__head">
              <span class="danger">
                <font-awesome-icon
                  icon={this.dataIconCheckCircle}/>
              </span>
              &nbsp;Manually
            </div>
            <div class="activity-item__body">

              {this.objCard?.manually_closed_tasks_count || 0}
            </div>
            <div class="activity-item__footer">
              <span
                class={this.objCard?.manually_closed_tasks_count_difference >= 0 ? 'success' : 'danger'}>{this.objCard?.manually_closed_tasks_count_difference > 0 && '+'}{this.objCard?.manually_closed_tasks_count_difference || 0}</span>
              <p>|</p>
              <span class={this.objCard?.manually_closed_tasks_count_difference_percents >= 0 ? 'success' : 'danger'}><font-awesome-icon
                icon={(this.objCard?.manually_closed_tasks_count_difference_percents >= 0 ? this.dataIconArrowUp : this.dataIconArrowDown)}/>
                &nbsp;{_templatePositiveNumber(this.objCard?.manually_closed_tasks_count_difference_percents) || 0}%</span>
            </div>
          </div>
        </div>

      </div>
    );
  }
};
